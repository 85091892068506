import React from 'react';
import { 
  FaInstagram, 
  FaLinkedinIn, 
  FaTwitter, 
  FaDribbble, 
  FaGithub,
  FaMapMarkerAlt 
} from 'react-icons/fa';
import logo from '../logo.svg'; // Ensure this path is correct

const Footer = () => {
  const quickLinks = [
    'About', 
    'Academics', 
    'Entrepreneurship', 
    'Innovation', 
    'Events', 
    'Startup Support', 
    'Resources', 
    'Contact Us'
  ];

  return (
    <footer className="py-12 text-gray-700 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 dark:bg-gradient-to-r dark:from-gray-900 dark:via-gray-800 dark:to-gray-900 dark:text-gray-300">
      <div className="container grid grid-cols-1 gap-8 px-4 mx-auto md:grid-cols-3 lg:gap-12">
        {/* Brand and Logo Section */}
        <div className="flex flex-col items-center justify-center space-y-4">
          <img 
            src={logo} 
            alt='E-Cell SCSIT DAVV INDORE' 
            className='object-contain w-auto h-24 p-2 dark:bg-white rounded-xl'
          />
          <h2 className="text-2xl font-bold text-center text-teal-600">
            E-Cell SCSIT DAVV
          </h2>
          <div className="flex justify-center space-x-6">
            {[
              { Icon: FaInstagram, href: "https://www.instagram.com/ecell_scsit", label: "Instagram" },
              { Icon: FaLinkedinIn, href: "https://www.linkedin.com/company/ecell-scsit", label: "LinkedIn" },
              { Icon: FaTwitter, href: "https://twitter.com/ecell_scsit", label: "Twitter" },
              { Icon: FaDribbble, href: "/", label: "Dribbble" },
              { Icon: FaGithub, href: "/", label: "GitHub" }
            ].map(({ Icon, href, label }) => (
              <a
                key={href}
                href={href}
                target="_blank"
                rel="noreferrer"
                aria-label={label}
                className="text-gray-700 transition-colors dark:text-gray-300 hover:text-teal-600 dark:hover:text-teal-400"
              >
                <Icon className="w-6 h-6" />
              </a>
            ))}
          </div>
          <address className="text-center text-gray-600 dark:text-gray-300">
            School of Computer Science and Information Technology
            Indore, Madhya Pradesh
          </address>
        
        </div>

     

        {/* Social and Location Section */}
        <div className="items-center w-full col-span-2 space-y-6 overflow-hidden shadow-lg h-96 rounded-xl">
          
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.035780697775!2d75.8732211!3d22.6897119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fce62a06c98b%3A0x9df2fc5c91f45eb5!2sSCSIT%20-%20School%20of%20Computer%20Science%20%26%20Information%20Technology%2C%20DAVV!5e0!3m2!1sen!2sin!4v1705476178658!5m2!1sen!2sin"
              className="w-full h-full"
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
        </div>
      </div>

      {/* Copyright and Developer Credit */}
      <div className="pt-6 mt-8 border-t border-gray-200 dark:border-gray-700">
        <div className="container flex flex-col items-center justify-center px-4 mx-auto space-y-4">
            <a 
              href="https://cityji.github.io/" 
              
            >
          <p className="text-center text-gray-600 hover:text-teal-600 dark:text-gray-400">
            &copy; {new Date().getFullYear()} E-Cell SCSIT DAVV All Rights Reserved. 
            Designed  and developed  by{' '}
             <b className="text-teal-600 dark:text-teal-400"> Nikhil Tiwari </b>
          </p>
            </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;