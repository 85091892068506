import React from 'react'
import Nav from '../components/Nav'
import Hero from '../components/Hero'
import Cta from '../components/Cta'
import Footer from '../components/Footer'
import Outline from '../components/Outline'
import SumanQuote from '../components/SumanQuote'
// import Calander from './components/Calander'
import About from '../components/About'

const Index = () => {
  return (
    <>
    <Nav/>
    <Hero/>
    <About/>
    <SumanQuote/>
    <Cta/>
    {/* <Calander/> */}
    <Outline/>
    <Footer/>
    </>
  )
}

export default Index