import React from "react";
import SumanQuote from "./SumanQuote";
import { FaLinkedin, FaInstagram, FaDribbble } from 'react-icons/fa';
const faculties = [
  {
    id: 1,
    name: "Dr. Ugrasen Suman",
    designation: "Head of Department",
    role: "Faculity Head E-Cell Committee",
    image: require('./src/Dr.Suman.jpg'),
    Url:'https://scs.dauniv.ac.in/u_suman.php',
  },
  {
    id: 3,
    name: "Dr. Shraddha Masih",
    designation: "Professor",
    role: "Placement Coordinator",
    image: require('./src/shradha.jpeg'), 
    Url:'https://scs.dauniv.ac.in/s_masih.php',
  },
  {
    id: 2,
    name: "Anand Singh Jalal",
    designation: "Professor",
    role: "Faculty Coordinator",
    image: require('./src/jalal.jpeg'), 
    Url:'https://scs.dauniv.ac.in/Anand-Singh-Jalal.php',
  },

];

const team = [
  {
      avatar: require('./src/suraj.jpg'),
      name: "Suraj Karan Singh",
      title: "President",
      linkedin: "https://www.linkedin.com/in/suraj-karan-singh-561a39250",
      Instagram: "https://www.instagram.com/_suraj_30_6ks02",
      
  },
  {
      avatar: require('./src/harsh.jpg'),
      name: "Harsh Soni",
      title: "Vice President",
      linkedin: "https://www.linkedin.com/in/hashprog",
      Instagram: "https://www.instagram.com/hash.prog",
  },

  {
      avatar: require('./src/nikhil.jpg'),
      name: "Nikhil Tiwari",
      title: "Technical Lead",
      linkedin: "https://www.linkedin.com/in/nikhil-tiwari-502177209",
      Instagram: "https://www.instagram.com/au_wild",
      Url: "https://cityji.github.io/",
  },
];

function Message() {
  return (
    
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gradient-to-br from-indigo-100 to-indigo-300 dark:from-gray-800 dark:to-gray-900">

      {/* Faculties Section */}
      <div className="w-full mt-12 max-w-7xl">
        <h2 className="mb-8 text-3xl font-bold text-center text-gray-800 dark:text-gray-200 animate-fadeIn">
          Meet Our Faculties
        </h2>

        <div className="grid justify-center grid-cols-1 gap-8 text-center sm:grid-cols-2 lg:grid-cols-3">
          {faculties.map((faculty) => (
            <a href={faculty.Url ? faculty.Url : "https://scs.dauniv.ac.in/faculty.php"} target="_blank" rel="noopener noreferrer">
              <div
                key={faculty.id}
                className="flex flex-col items-center p-6 transition duration-300 transform bg-white shadow-lg dark:bg-gray-800 rounded-2xl hover:scale-105"
              >
                {/* Faculty Image */}
                <img
                  src={faculty.image}
                  alt={faculty.name}
                  className="object-cover mb-4 border-4 border-indigo-400 rounded-full shadow-md h-52 dark:border-indigo-600"
                />

                {/* Faculty Info */}
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  {faculty.name}
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {faculty.designation}
                </p>
                <p className="mt-2 text-sm font-medium text-indigo-600 dark:text-indigo-400">
                  {faculty.role}
                </p>
              </div>
            </a>
          ))}
        </div>
      </div>
      
{/* Decission makers Section */}
<div className="w-full px-6 mt-12 max-w-7xl">
  <h2 className="mb-8 text-3xl font-bold text-center text-gray-800 dark:text-gray-200 animate-fadeIn">
    Founding Members
  </h2>

  <div className="grid justify-center grid-cols-1 gap-8 text-center sm:grid-cols-2 lg:grid-cols-3">
    {team.map((member, index) => (
      <a href={member.Url ? member.Url : "#"} target="_blank" rel="noopener noreferrer">
      <div
        key={index}
        className="flex flex-col items-center p-6 transition duration-300 transform bg-white shadow-xl dark:bg-gray-800 rounded-2xl hover:scale-105 hover:shadow-2xl"
      >
        {/* Avatar */}
        <img
          src={member.avatar}
          alt={member.name}
          className="object-cover mb-4 border-4 border-indigo-400 rounded-full shadow-md h-52 dark:border-indigo-600"
        />

        {/* Member Info */}
        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
          {member.name}
        </h3>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {member.title}
        </p>

        {/* Social Links */}
        <div className="flex mt-4 space-x-4">
          {member.linkedin && (
            <a
              href={member.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="text-xl text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-600"
            >
              <FaLinkedin />
            </a>
          )}
          {member.Instagram && (
            <a
              href={member.Instagram}
              target="_blank"
              rel="noopener noreferrer"
              className="text-xl text-pink-600 dark:text-pink-400 hover:text-pink-800 dark:hover:text-pink-600"
            >
              <FaInstagram />
            </a>
          )}
          {member.Url && (
            <a
              href={
                member.Url
              }
              target="_blank"
              rel="noopener noreferrer"
              className="text-xl text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-600"
            >
              <FaDribbble />
            </a>
          )  
          }
        </div>
      </div>
      </a>
      
    ))}
  </div>
</div>
    </div>
 
  );
}

export default Message;
