import React from 'react'

function SumanQuote() {
  return (
    <div className="relative flex flex-col items-center justify-center w-screen p-6 px-32 py-32 overflow-hidden transition-all duration-500 transform bg-white shadow-2xl md:flex-row md:p-10 dark:bg-gray-800 ">
          <div className="relative flex flex-col md:flex-row items-center  p-6 md:p-10 bg-white dark:bg-gray-800 rounded-2xl shadow-2xl overflow-hidden transform transition-all duration-500 hover:scale-[1.02]">
        {/* Left Section: Image */}
        <div className="flex-shrink-0 w-32 h-32 mb-6 md:w-48 md:h-48 md:mb-0 md:mr-10 animate-fadeIn">
          <img
            src={require("./src/Dr.Suman.jpg")}
            alt="Avatar"
            className="object-cover w-full h-full border-4 border-indigo-400 rounded-full shadow-lg dark:border-indigo-600"
          />
        </div>

        {/* Right Section: Quote */}
        <div className="flex-1 text-center md:text-left animate-slideIn">
          <blockquote className="text-lg font-medium leading-relaxed text-gray-700 md:text-2xl dark:text-gray-300">
            “We wish all students for achieving their academic excellence with
            life skills. We will always look forward to achieving the heights of
            accomplishment towards holistic development of students.”
          </blockquote>
          <footer className="mt-4 text-base font-semibold text-gray-500 dark:text-gray-400 md:text-lg">
            &mdash; Dr. Ugrsen Suman
          </footer>

          {/* Interactive Button */}
          {/* <div className="flex justify-center mt-6 md:justify-start">
            <button
              onClick={() => alert("Keep striving for excellence!")}
              className="px-6 py-3 font-semibold text-white transition duration-300 ease-in-out transform bg-indigo-500 rounded-full shadow-md hover:bg-indigo-600 hover:scale-105 dark:bg-indigo-600 dark:hover:bg-indigo-700"
            >
              Inspire Me
            </button>
          </div> */}
        </div>
    </div>
    </div>
  )
}

export default SumanQuote;