import React from 'react'
import { useState } from 'react'
import './Nav.css'


function Nav() {
    const [isActive, setIsActive] = useState(false);

    const handleClick = event => {
        // 👇️ toggle isActive state on click
        setIsActive(current => !current);
        const nav = document.getElementById('NavHide');
        const navElement = document.querySelector('#NavHide ul');
        const navGrow = document.getElementById('GrowwOnMob');
        if (isActive) {
            nav.classList.add('hidden');
            navGrow.classList.remove('growwHeight');
            navElement.classList.remove('NavUlFlex');
        }
        else {
            navElement.classList.add('NavUlFlex');
            navGrow.classList.add('growwHeight')
            nav.classList.remove('hidden');
        }
    };


    return (
        <header id='Top' class="bg-white dark:bg-gray-700 dark:text-black z-50">
            <div id='GrowwOnMob' class="mx-auto max-w-screen-xl px-4 md:px-6 lg:px-4">
                <div class="flex h-16 items-center justify-between">
                    <div className='flex flex-row justify-center align-center'>
                        <div class="md:flex md:items-center md:gap-12">
                            <a class="block text-teal-600 dark:text-teal-600" href="/">
                                {/* <span class="sr-only">Home</span> */}
                                <img src={require('../e-cell.png')} alt='logo of e-cell davv' className='object-cover w-20 h-20 invert dark:invert-0' />
                            </a>
                        </div>
                        <a class="block text-teal-600 dark:text-teal-600" href="https://www.dauniv.ac.in/new/scs/" rel="noreferrer" target='_blank'>
                            <img className="h-12 mt-5 ml-3 dark:bg-white" src={require('./scs-logo.png')} alt="scsit logo | school of computer science logo" />
                        </a>

                    </div>

                    <div id='NavHide' class="hidden md:block">
                        <nav aria-label="Global">
                            <ul class="flex items-center  gap-6 text-md">

                                <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/#about"
                                    >
                                        About
                                    </a>
                                </li>

                                <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/Team"
                                    >
                                        Team
                                    </a>
                                </li>

                                <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/gallery"
                                    >
                                        Gallery
                                    </a>
                                </li>


                                <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/#Vision"
                                    >
                                        Vision
                                    </a>
                                </li>

                                <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/#Activities"
                                    >
                                        Activities
                                    </a>
                                </li>

                                <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/soon"
                                    >
                                        Projects
                                    </a>
                                </li>

                                {/* <li>
                                    <a
                                        class="text-gray-500 transition hover:text-gray-500/75 dark:text-white dark:hover:text-white/75"
                                        href="/"
                                    >
                                        Blog
                                    </a>
                                </li> */}
                            </ul>
                        </nav>
                    </div>


                    <div class="flex items-center gap-4">

                        {/* join btn without js */}
                        <ul class="space-y-1">
                            <details class="group [&_summary::-webkit-details-marker]:hidden">
                                <summary
                                    class=" relative rounded-md bg-teal-600 px-5 py-2.5 w-20 text-md font-medium text-white shadow dark:hover:bg-teal-600  hover:bg-teal-500 hover:text-white flex cursor-pointer items-center justify-between "
                                >
                                    <a href='#' class="text-sm font-medium w-20"> Join </a>

                                    <span class="shrink-0 transition duration-300 group-open:-rotate-180">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </span>
                                </summary>

                                <ul className='absolute z-10'>
                                    <li className='my-6'>
                                        <a
                                            target='_blank' rel='noreferrer'
                                            href="https://docs.google.com/forms/d/e/1FAIpQLSeXOuCApysXmQXtKXvdUfHkZJysjqgx1SvyWip_po3-gEOeYg/viewform?usp=sharing"
                                            class=" rounded-lg  px-5 py-3 text-sm font-medium text-gray-500 hover:bg-teal-500 bg-gray-300 hover:text-white"
                                        >
                                            As Member
                                        </a>
                                    </li>

                                    {/* <li className='my-5'>
                                        <a
                                            target='_blank' rel='noreferrer'
                                            href="https://docs.google.com/forms/d/e/1FAIpQLSd1qudhaQX28Vpo5_Pg30BSL5RxQsdjmrNesPLeL3Yb3JGGDg/viewform?usp=sf_link"
                                            class=" rounded-lg px-5 py-3 text-sm font-medium text-gray-500 hover:bg-teal-500 bg-gray-300 hover:text-white"
                                        >
                                            As A Learner
                                        </a>
                                    </li> */}
                                </ul>
                            </details>
                        </ul>
                        {/* end of Join btn  */}
                        <div class="block md:hidden">
                            <button
                                onClick={handleClick}
                                class="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75 dark:bg-gray-800 dark:text-white dark:hover:text-white/75"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}

export default Nav